import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Stack, TextField } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { XspExportService } from 'src/services/export/xsp-export.service';

import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { XspOdataExportService } from '../../../services/export/xsp-odata-export.service';
import { TableAutocompleteFieldWidth } from '../../../styles/content-tables';

interface ExportXspControlsProps {
  selectedRulesets: RulesetDto[];
  downloadFile: (blob: Blob, fileName: string) => void;
  formatXspFilename: (name: string, contentType: string, suffix: string) => string;
  functionContentTypeName?: string;
  setAlert: React.Dispatch<
    React.SetStateAction<{
      success?: string | undefined;
      error?: string | undefined;
    }>
  >;
}

export function ExportXspControls({
  selectedRulesets,
  downloadFile,
  formatXspFilename,
  functionContentTypeName,
  setAlert,
  ...props
}: ExportXspControlsProps) {
  const xspExportService = useMemo(() => new XspExportService(), []);
  const xspOdataExportService = useMemo(() => new XspOdataExportService(), []);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      baseUrl: 'https://8a9a3e41-0c03-1edf-84a6-d912a14431f1.abap.eu10.hana.ondemand.com',
      baseAuthUser: 'XCP_CRAF_RULESET',
      basesAuthPassword: '',
    },
  });

  const registerOptions = {
    baseUrl: { required: true },
    baseAuthUser: { required: true },
    basesAuthPassword: { required: true },
  };

  const onRulesetsFullExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportRulesetsFull(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'RULES', 'FULL'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onRulesetsHeaderExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportRulesetsHeader(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'RULES', 'HDR'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onRulesetsDefinitionExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportRulesetsDefiniton(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'RULES', 'DEF'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onRisksFullExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportRisksFull(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'RISK', 'FULL'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onRisksHeaderExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportRisksHeader(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'RISK', 'HDR'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onRisksDefinitionExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportRisksDefiniton(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'RISK', 'DEF'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onFunctionsFullExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportFunctionsFull(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'FUNC', 'FULL'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onFunctionsHeaderExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportFunctionsHeader(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'FUNC', 'HDR'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onFunctionsDefinitionExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await xspExportService.exportFunctionsDefiniton(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatXspFilename(selectedRulesets[0].name, 'FUNC', 'DEF'));
    }
  }, [selectedRulesets, xspExportService, downloadFile, formatXspFilename, functionContentTypeName]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      if (selectedRulesets.length && functionContentTypeName) {
        await xspOdataExportService.exportXspAll(
          selectedRulesets.map((ruleset) => ruleset.id),
          functionContentTypeName,
          {
            baseUrl: data.baseUrl,
            baseAuthUser: data.baseAuthUser,
            basesAuthPassword: data.basesAuthPassword,
          },
        );
      }
    },
    [functionContentTypeName, selectedRulesets, xspOdataExportService],
  );

  return (
    <Stack spacing={2} sx={{ width: 2.5 * TableAutocompleteFieldWidth }}>
      <Divider>File Download</Divider>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRulesetsFullExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Ruleset
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRulesetsHeaderExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Ruleset Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRulesetsDefinitionExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Ruleset Definition
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRisksFullExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRisksHeaderExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRisksDefinitionExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk Definition
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFunctionsFullExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFunctionsHeaderExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFunctionsDefinitionExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Definition
        </Button>
      </Stack>
      <Divider>Direct Transport</Divider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction='column' spacing={2}>
          <Controller
            name='baseUrl'
            control={control}
            rules={registerOptions.baseUrl}
            render={({ field }) => (
              <TextField
                margin='dense'
                label='URL'
                fullWidth
                error={!!errors.baseUrl}
                helperText={errors.baseUrl?.message?.toString()}
                {...field}
              />
            )}
          />
          <Controller
            name='baseAuthUser'
            control={control}
            rules={registerOptions.baseAuthUser}
            render={({ field }) => (
              <TextField
                margin='dense'
                label='User'
                fullWidth
                error={!!errors.baseAuthUser}
                helperText={errors.baseAuthUser?.message?.toString()}
                {...field}
              />
            )}
          />
          <Controller
            name='basesAuthPassword'
            control={control}
            rules={registerOptions.basesAuthPassword}
            render={({ field }) => (
              <TextField
                type='password'
                margin='dense'
                label='Password'
                fullWidth
                error={!!errors.basesAuthPassword}
                helperText={errors.basesAuthPassword?.message?.toString()}
                {...field}
              />
            )}
          />
          <Button type='submit' variant='contained' color='success' disabled={!selectedRulesets.length}>
            Transport to XSP
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
