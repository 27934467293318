import { Box, Paper, Skeleton, Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';
import { CreateFromTemplateRiskFunctionDto } from '@/services/dto/functions/create-from-existing-function.dto';
import { CreateNewVersionRiskFunctionDto } from '@/services/dto/functions/create-new-version-function.dto';
import { RiskLevelDto } from '@/services/dto/risk-levels/risk-level.dto';

import RiskBreadcrumbs from '../../components/breadcrumbs/risk';
import {
  DefaultChildrenBaseContentOrderBy,
  DefaultChildrenContentType,
} from '../../components/children-table/children-table-body-cell';
import { ContentTable } from '../../components/content-table';
import { sortTableDataStatus } from '../../components/content-table-head';
import EditRiskForm from '../../components/edit-risk-header';
import { RiskFunctionsOrderBy, sortRiskFunctions } from '../../components/functions/function-table-head';
import {
  confirmRemoveRiskFunctionFromRisk,
  confirmRemoveRiskFunctionsFromRisk,
} from '../../components/functions/remove-dialogs';
import { CreateRiskFunctionDto } from '../../services/dto/functions/create-function.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { UpdateRiskFunctionDto } from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import {
  useBusinessProcessService,
  useRiskFunctionService,
  useRiskLevelService,
  useRiskService,
  useRulesetService,
} from '../../services/hooks';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import CreateRiskFunctionModal from '../functions/functions-modal/create';
import CreateRiskFunctionFromTemplateModal from '../functions/functions-modal/create-from-template';
import CreateRiskFunctionNewVersionModal from '../functions/functions-modal/create-new-version';
import UpdateRiskFunctionModal from '../functions/functions-modal/update';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskDetailsPageProps {}

const RiskDetailsPage: FunctionComponent<RiskDetailsPageProps> = ({ ...props }) => {
  const [ruleset, setRuleset] = useState<RulesetDto | undefined>(undefined);
  const [risk, setRisk] = useState<RiskDto | undefined>(undefined);
  const [rulesetsFromRisk, setRulesetsFromRisk] = useState<RulesetDto[] | undefined>(undefined);
  const [riskFunctions, setRiskFunctions] = useState<RiskFunctionDto[] | null>(null);
  const [riskFunctionsTotalCount, setRiskFunctionsTotalCount] = useState(0);
  const [riskVersionHistory, setRiskVersionHistory] = useState<RiskDto[]>([]);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);
  const [riskLevels, setRiskLevels] = useState<RiskLevelDto[]>([]);
  const [allRiskFunctions, setAllRiskFunctions] = useState<RiskFunctionDto[] | undefined>(undefined);
  const [modalRiskFunction, setModalRiskFunction] = useState<RiskFunctionDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'update' | 'createNewVersion' | 'createFromTemplate' | undefined
  >(undefined);

  const routeParams = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const rulesetService = useRulesetService();
  const riskService = useRiskService();
  const riskFunctionService = useRiskFunctionService();
  const businessProcessService = useBusinessProcessService();
  const riskLevelService = useRiskLevelService();

  const fetchRiskVersionHistory = useCallback(
    async (riskId: string) => {
      const riskVersionHistory = await riskService.getRiskVersionHistory(riskId);
      setRiskVersionHistory(riskVersionHistory.data);
    },
    [riskService],
  );

  const fetchFunctionsByRiskId = useCallback(
    async (riskId: string) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(riskId);
      setRiskFunctions(sortTableDataStatus('asc', riskFunctions.data));
      setRiskFunctionsTotalCount(riskFunctions.meta?.totalCount ?? riskFunctions.data.length);
      return riskFunctions.data;
    },
    [riskService],
  );

  const fetchRelationsByRiskId = useCallback(
    async (riskId: string) => {
      const riskResult = await riskService.getRisk(riskId);
      const rulesetsFromRisk = await riskService.getRulesetsFromRisk(riskId);
      setRisk(riskResult.data);
      setRulesetsFromRisk(rulesetsFromRisk.data);
      fetchFunctionsByRiskId(riskId);
    },
    [fetchFunctionsByRiskId, riskService],
  );

  const fetchRulesetsFromRisk = useCallback(
    async (riskId: string) => {
      const rulesetsFromRisk = await riskService.getRulesetsFromRisk(riskId);
      setRulesetsFromRisk(rulesetsFromRisk.data);
    },
    [riskService],
  );

  const fetchRuleset = useCallback(
    async (rulesetId: string) => {
      const rulesetResult = await rulesetService.getRuleset(rulesetId);
      setRuleset(rulesetResult.data);
    },
    [rulesetService],
  );

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
  }, [businessProcessService]);

  const fetchRiskLevels = useCallback(async () => {
    const riskLevelsResult = await riskLevelService.getRisklevels();
    setRiskLevels(riskLevelsResult.data);
  }, [riskLevelService]);

  const onSave = useCallback(
    async (data: FieldValues) => {
      try {
        if (risk) {
          const updateRisk = {
            name: risk.name != data.name ? data.name : risk.name,
            displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
            description: risk.description != data.description ? data.description : risk.description,
            riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
            criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
            riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
            businessProcessId:
              risk.businessProcess?.id != data.businessProcess ? data.businessProcess ?? null : undefined,
          };
          await riskService.updateRisk(risk.id, updateRisk);
          setRisk({
            ...risk,
            name: risk.name != data.name ? data.name : risk.name,
            displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
            description: risk.description != data.description ? data.description : risk.description,
            riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
            criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
            riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
            businessProcess:
              risk.businessProcess?.id != data.businessProcess
                ? allBusinessProcesses.find((bp) => bp.id === data.businessProcess) ?? null
                : risk.businessProcess,
          });
          fetchRiskVersionHistory(risk.id);
        }
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRiskVersionHistory, risk, riskService, allBusinessProcesses],
  );

  const onConfirmFinalize = useCallback(
    async (risk: RiskDto, data: FieldValues) => {
      try {
        const updateRisk = {
          name: risk.name != data.name ? data.name : risk.name,
          displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
          description: risk.description != data.description ? data.description : risk.description,
          riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
          criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
          riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
          businessProcessId:
            risk.businessProcess?.id != data.businessProcess ? data.businessProcess ?? null : undefined,
          status: ContentTypeStatus.Final,
        };
        await riskService.updateRisk(risk.id, updateRisk);
        setRisk({
          ...risk,
          name: risk.name != data.name ? data.name : risk.name,
          displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
          description: risk.description != data.description ? data.description : risk.description,
          riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
          criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
          riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
          businessProcess:
            risk.businessProcess?.id != data.businessProcess
              ? allBusinessProcesses.find((bp) => bp.id === data.businessProcess) ?? null
              : risk.businessProcess,
          status: ContentTypeStatus.Final,
        });
        fetchFunctionsByRiskId(risk.id);
        fetchRiskVersionHistory(risk.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchFunctionsByRiskId, fetchRiskVersionHistory, riskService, allBusinessProcesses],
  );

  const onConfirmRelease = useCallback(
    async (risk: RiskDto) => {
      try {
        const updateRisk = {
          status: ContentTypeStatus.Released,
        };
        await riskService.updateRisk(risk.id, updateRisk);
        setRisk({
          ...risk,
          status: ContentTypeStatus.Released,
        });
        fetchFunctionsByRiskId(risk.id);
        fetchRiskVersionHistory(risk.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchFunctionsByRiskId, fetchRiskVersionHistory, riskService],
  );

  const onRiskFunctionCreated = useCallback(async (newRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => [newRiskFunction, ...(riskFunctions || [])]);
    setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount + 1);
  }, []);

  const onNewRiskFunctionVersion = useCallback(
    (newRiskFunction: RiskFunctionDto, origin: RiskFunctionDto, updateParents: Partial<RiskDto>[]) => {
      // forward to new created parent
      const newParent = updateParents.find((p) => p.name === risk?.name);
      if (newParent) {
        navigate(`/risks/${newParent.id}`);
      }
    },
    [navigate, risk?.name],
  );

  const fetchRisk = useCallback(
    async (riskId: string) => {
      const riskResult = await riskService.getRisk(riskId);
      setRisk(riskResult.data);
      fetchRiskVersionHistory(riskId);
      fetchBusinessProcesses();
      fetchRiskLevels();
    },
    [riskService, fetchRiskVersionHistory, fetchBusinessProcesses, fetchRiskLevels],
  );

  const onRiskFunctionUpdated = useCallback(async (id: string, updateRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.map((riskFunction) => {
          if (riskFunction.id === id) {
            return { ...riskFunction, ...updateRiskFunction };
          }
          return riskFunction;
        });
      }
      return null;
    });
  }, []);

  const onRiskFunctionRemoved = useCallback((riskId: string, functionId: string) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.filter((riskFunction) => riskFunction.id !== functionId);
      }
      return null;
    });
    setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount - 1);
  }, []);

  const onRiskFunctionsRemoved = useCallback((functionIds: string[]) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.filter(
          (riskFunction: RiskFunctionDto) => !functionIds.some((fId) => fId === riskFunction.id),
        );
      }
      return null;
    });
    setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount - functionIds.length);
  }, []);

  const onRiskFunctionAdded = useCallback(
    async (riskId: string, functionId: string, riskFunction: RiskFunctionDto | null) => {
      if (riskFunction) {
        setRiskFunctions((riskFunctions) => {
          if (riskFunctions) {
            return [riskFunction, ...(riskFunctions ?? [])];
          }
          return null;
        });
        setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount + 1);
      }
    },
    [],
  );

  const fetchAllRiskFunctions = useCallback(async () => {
    if (!allRiskFunctions) {
      const riskFunctions = await riskFunctionService.getFunctions();
      setAllRiskFunctions(riskFunctions.data);
    }
  }, [riskFunctionService, allRiskFunctions]);

  const openCreateRiskFunction = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openCreateFromTemplateRiskFunction = useCallback(() => {
    setModalRiskFunction(undefined);
    setCurrentOpenModal('createFromTemplate');
  }, []);

  const openEditRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRiskFunction(undefined);
  }, []);

  const onRiskFunctionCreate = useCallback(
    async (data: CreateRiskFunctionDto) => {
      const res = await riskFunctionService.createFunction(data);
      onRiskFunctionCreated(res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionCreated, riskFunctionService],
  );

  const onRiskFunctionFinalize = useCallback(
    async (riskFunctionId: string, data: UpdateRiskFunctionDto) => {
      try {
        const res = await riskFunctionService.updateFunction(riskFunctionId, data);
        onRiskFunctionUpdated(riskFunctionId, res.data);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskFunctionService, onRiskFunctionUpdated],
  );

  const onRiskFunctionRelease = useCallback(
    async (riskFunctionId: string, data: UpdateRiskFunctionDto) => {
      try {
        const res = await riskFunctionService.updateFunction(riskFunctionId, data);
        onRiskFunctionUpdated(riskFunctionId, res.data);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskFunctionService, onRiskFunctionUpdated],
  );

  const onRiskFunctionNewVersionCreate = useCallback(
    async (id: string, data: CreateNewVersionRiskFunctionDto, origin: RiskFunctionDto) => {
      if (risk) {
        const res = await riskFunctionService.createNewFunctionVersion(id, data);
        onNewRiskFunctionVersion(res.data, origin, res.meta.updateParents);
        closeModal();
      }
    },
    [riskFunctionService, risk, onNewRiskFunctionVersion, closeModal],
  );

  const onRiskFunctionCreateFromTemplate = useCallback(
    async (data: CreateFromTemplateRiskFunctionDto, origin: RiskFunctionDto) => {
      if (risk) {
        const res = await (() => {
          return riskFunctionService.createFromExistingFunction(origin.id, data);
        })();
        onRiskFunctionCreated(res.data);
        closeModal();
      }
    },
    [onRiskFunctionCreated, closeModal, riskFunctionService, risk],
  );

  const onRiskFunctionUpdate = useCallback(
    async (id: string, data: UpdateRiskFunctionDto) => {
      const res = await riskFunctionService.updateFunction(id, data);
      onRiskFunctionUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionUpdated, riskFunctionService],
  );

  const removeRiskFunctionFromRisk = useCallback(
    async (risk: RiskDto, functionId: string) => {
      await riskService.removeFunctionFromRisk(risk.id, functionId);
      onRiskFunctionRemoved(risk.id, functionId);
    },
    [onRiskFunctionRemoved, riskService],
  );

  const removeMultipleRiskFunctionsFromRisk = useCallback(
    async (functionIds: string[]) => {
      if (risk) {
        await riskService.removeFunctionsFromRisk(risk.id, functionIds);
        onRiskFunctionsRemoved(functionIds);
      }
    },
    [onRiskFunctionsRemoved, risk, riskService],
  );

  const addRiskFunctionToRisk = useCallback(
    async (risk: RiskDto, riskFunction: RiskFunctionDto) => {
      if (risk && riskFunction) {
        await riskService.addFunctionToRisk(risk.id, riskFunction.id);
        onRiskFunctionAdded(risk.id, riskFunction.id, riskFunction);
      }
    },
    [onRiskFunctionAdded, riskService],
  );

  const loadFunctionWithValues = useCallback(
    async (riskFunctionId: string) => {
      const ret = await riskFunctionService.getFunction(riskFunctionId);
      return ret.data;
    },
    [riskFunctionService],
  );

  const onRemoveRiskFunctionFromRisk = useCallback(
    async (riskFunction: RiskFunctionDto) => {
      const { dialog } = await confirmRemoveRiskFunctionFromRisk(confirm, riskFunction);
      dialog
        .then(() => {
          if (risk) {
            removeRiskFunctionFromRisk(risk, riskFunction.id);
          }
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, removeRiskFunctionFromRisk, risk],
  );

  const onMassDelete = useCallback(
    async (riskFunctionIds: string[]) => {
      const { dialog } = await confirmRemoveRiskFunctionsFromRisk(confirm, riskFunctionIds);
      return dialog
        .then(() => {
          if (risk) {
            removeMultipleRiskFunctionsFromRisk(riskFunctionIds);
            return true;
          }
          return false;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [confirm, removeMultipleRiskFunctionsFromRisk, risk],
  );

  const getRiskFunctionUrl = useCallback(
    (riskFunction: RiskFunctionDto) => {
      if (ruleset && risk) {
        return `/rulesets/${ruleset.id}/risks/${risk.id}/functions/${riskFunction.id}`;
      } else if (risk) {
        return `/risks/${risk.id}/functions/${riskFunction.id}`;
      }
      return `/functions/${riskFunction.id}`;
    },
    [ruleset, risk],
  );

  useEffect(() => {
    if (routeParams.rulesetId) {
      fetchRuleset(routeParams.rulesetId);
    } else {
      setRuleset(undefined);
    }

    if (routeParams.riskId) {
      fetchRisk(routeParams.riskId);
      fetchRelationsByRiskId(routeParams.riskId);
      fetchRulesetsFromRisk(routeParams.riskId);
    } else {
      setRisk(undefined);
      setRiskFunctions([]);
      setRulesetsFromRisk([]);
    }
  }, [fetchRelationsByRiskId, fetchRisk, fetchRuleset, fetchRulesetsFromRisk, routeParams]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        {risk && <RiskBreadcrumbs ruleset={ruleset} risk={risk} />}
        {!risk && <Skeleton variant='rounded' height={56} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {risk && (
          <EditRiskForm
            riskVersionHistory={riskVersionHistory}
            risk={risk}
            riskFunctions={riskFunctions ?? []}
            rulesets={rulesetsFromRisk}
            riskLevels={riskLevels}
            allBusinessProcesses={allBusinessProcesses}
            onSave={onSave}
            onConfirmFinalize={onConfirmFinalize}
            onConfirmRelease={onConfirmRelease}
          />
        )}
        {!risk && <Skeleton variant='rounded' height={272} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {risk && rulesetsFromRisk && (
          <Box>
            <ContentTable<
              RiskFunctionDto,
              RiskFunctionsOrderBy,
              DefaultChildrenContentType,
              DefaultChildrenBaseContentOrderBy,
              RiskDto
            >
              tableToolbarTitle='Functions'
              totalCount={riskFunctionsTotalCount}
              parent={risk}
              rows={riskFunctions}
              sortTable={sortRiskFunctions}
              openCreate={openCreateRiskFunction}
              createButtonTitle='Create Function'
              createFromTemplateButtonTitle='Create Function From Template'
              openCreateNewVersion={openCreateNewVersionRiskFunction}
              openCreateFromTemplate={openCreateFromTemplateRiskFunction}
              openEdit={openEditRiskFunction}
              onRemoveFromParent={onRemoveRiskFunctionFromRisk}
              getDetailsUrl={getRiskFunctionUrl}
              defaultOrderBy='name'
              template={{
                allOptions: allRiskFunctions,
                label: 'Add Function',
                buttonLabel: 'Add Function to Risk',
                loadAllOptions: fetchAllRiskFunctions,
                addChildToParent: addRiskFunctionToRisk,
              }}
              massDeletion={{
                onMassDelete: onMassDelete,
                tooltipTitle: 'Remove selected',
                iconType: 'remove',
              }}
            />
            <CreateRiskFunctionModal
              open={currentOpenModal === 'create'}
              onClose={closeModal}
              onRiskFunctionCreate={onRiskFunctionCreate}
              allBusinessProcesses={allBusinessProcesses}
              risk={risk}
            />
            <CreateRiskFunctionFromTemplateModal
              open={currentOpenModal === 'createFromTemplate'}
              allBusinessProcesses={allBusinessProcesses}
              onClose={closeModal}
              onRiskFunctionCreateFromTemplate={onRiskFunctionCreateFromTemplate}
              allRiskFunctions={allRiskFunctions}
              loadAllRiskFunctions={fetchAllRiskFunctions}
              risk={risk}
            />
            {modalRiskFunction && (
              <UpdateRiskFunctionModal
                allBusinessProcesses={allBusinessProcesses}
                open={currentOpenModal === 'update'}
                onClose={closeModal}
                onRiskFunctionUpdate={onRiskFunctionUpdate}
                onRiskFunctionFinalize={onRiskFunctionFinalize}
                loadFunctionWithValues={loadFunctionWithValues}
                riskFunction={modalRiskFunction}
              />
            )}
            {modalRiskFunction && (
              <CreateRiskFunctionNewVersionModal
                allBusinessProcesses={allBusinessProcesses}
                open={currentOpenModal === 'createNewVersion'}
                onClose={closeModal}
                riskFunction={modalRiskFunction}
                loadFunctionWithValues={loadFunctionWithValues}
                onRiskFunctionRelease={onRiskFunctionRelease}
                onRiskFunctionCreateNewVersion={onRiskFunctionNewVersionCreate}
              />
            )}
          </Box>
        )}
        {(!risk || !rulesetsFromRisk) && <Skeleton variant='rounded' height={300} />}
      </Paper>
    </Stack>
  );
};

export default RiskDetailsPage;
