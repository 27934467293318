import { FunctionComponent, useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { ContentTableModal } from '../../../components/content-table-modal';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { CreateFromExistingRulesetDto } from '../../../services/dto/rulesets/create-from-existing-ruleset.dto';
import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';

interface CreateRulesetFromTemplateFormData {
  name: string;
  displayName: string;
  description: string;
  riskIds?: string[];
  keepReference?: boolean;
}

interface RulesetModalProps {
  open: boolean;
  onClose: () => void;
  allRulesets: RulesetDto[];
  allRisks?: RiskDto[];
  loadRisksFromRuleset: (ruleset: RulesetDto) => Promise<RiskDto[]>;
  loadAllRisks: () => Promise<void>;
  loadAllRulesets?: () => Promise<void>;
  onRulesetCreateFromTemplate: (origin: RulesetDto, data: CreateFromExistingRulesetDto) => void;
}

const CreateRulesetFromTemplateModal: FunctionComponent<RulesetModalProps> = ({
  open,
  onClose,
  onRulesetCreateFromTemplate,
  allRulesets,
  allRisks,
  loadAllRisks,
  loadAllRulesets,
  loadRisksFromRuleset,
  ...props
}) => {
  const [disabled, setDisabled] = useState(true);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRulesetFromTemplateFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      keepReference: true,
    },
  });

  const onSelectNewTemplate = useCallback(
    (template: RulesetDto) => {
      setValue('name', template.name);
      setValue('displayName', template.displayName);
      setValue('description', template.description);
      setValue('keepReference', true);
      setDisabled(false);
    },
    [setValue],
  );

  const onCreateFromTemplate = useCallback(
    (data: FieldValues, template: RulesetDto, riskIds?: string[]) => {
      onRulesetCreateFromTemplate(template, {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        riskIds: riskIds,
        keepReference: data.keepReference,
      });
    },
    [onRulesetCreateFromTemplate],
  );

  return (
    <ContentTableModal<RulesetDto, CreateRulesetFromTemplateFormData, RiskDto>
      title='Create Ruleset'
      type='CreateFromTemplate'
      disabled={disabled}
      createFromTemplate={{
        onCreateFromTemplate,
        templates: allRulesets,
        loadAllTemplates: loadAllRulesets,
        onSelectNewTemplate,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Risks',
        loadChildren: loadRisksFromRuleset,
        loadAllPossibleChildren: loadAllRisks,
        allChildren: allRisks,
        addFieldLabel: 'Add Risks to Ruleset',
      }}
    ></ContentTableModal>
  );
};

export default CreateRulesetFromTemplateModal;
