import { faSave, faEdit, faTrash, faUndo, faAdd, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack } from '@mui/material';
import { darken } from '@mui/material/styles';
import {
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModel,
  GridColDef,
  GridActionsCellItem,
  DataGrid,
  useGridApiRef,
  GridValidRowModel,
  GridRowSelectionModel,
  GridCellParams,
  GridLogicOperator,
  GridRowsProp,
  GridValueSetterParams,
} from '@mui/x-data-grid';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';

import { RiskFunctionDto, RiskFunctionValueDto } from '../../../services/dto/functions/function.dto';
import { ContentTypeStatus } from '../../../services/models/content-type-status';
import { RiskFunctionOperator } from '../../../services/models/function-operator';
import { extendStringFilterOperators } from '../../../shared/data-grid';
import { searchStarFilter } from '../../../shared/utils';
import { DataGridRows } from '../context/data';
import { FunctionDefinitionsDataGridFooter } from './footer';
import FunctionsNoRowsOverlay from './no-row-overlay';

export interface BaseTableRow {
  id: string;
  group: string;
  position: number;
  operator: RiskFunctionOperator;
  isNew?: boolean;
}

export type FunctionDefinitionColumn = GridColDef & { filterable?: boolean };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionsDataGridProps<FunctionDefinitionValueTableRow extends BaseTableRow> {
  RowsContext: React.Context<DataGridRows>;
  contentTypeName: string;
  riskFunctionDefinitionColumns: FunctionDefinitionColumn[];
  riskFunction: RiskFunctionDto;
  saveValues: () => Promise<RiskFunctionDto | undefined>;
  riskFunctionValueToRow: (value: RiskFunctionValueDto) => FunctionDefinitionValueTableRow;
  defaultValue: FunctionDefinitionValueTableRow;
  filter?: {
    search: string;
    operator: 'contains' | 'notContains' | string;
  };
}

export function FunctionsDataGrid<FunctionDefinitionValueTableRow extends BaseTableRow>({
  RowsContext,
  contentTypeName,
  riskFunctionDefinitionColumns,
  riskFunction,
  saveValues,
  riskFunctionValueToRow,
  defaultValue,
  filter,
  ...prop
}: FunctionsDataGridProps<FunctionDefinitionValueTableRow>) {
  const apiRef = useGridApiRef();
  const rowsContext = useContext(RowsContext);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isSaving, setIsSaving] = useState(false);

  const onSingleEditButton = useCallback(
    (id: GridRowId, row: GridValidRowModel) => {
      rowsContext.setData((data) => {
        if (!data.rowsBeforeChange.has(id)) {
          data.rowsBeforeChange.set(id, row);
        }
        data.unsavedRows.set(id, {
          ...row,
          _action: 'edit',
        });
        return data;
      });
      if (apiRef.current.getRowMode(id) !== GridRowModes.Edit) {
        apiRef.current.startRowEditMode({ id });
      }
    },
    [apiRef, rowsContext],
  );

  const handleRowEditStart = useCallback<GridEventListener<'rowEditStart'>>(
    (params, event) => {
      rowsContext.setData((data) => {
        if (!data.rowsBeforeChange.has(params.id)) {
          data.rowsBeforeChange.set(params.id, { ...params.row, _action: 'edit' });
        }
        return data;
      });
      const row = rows.find((row) => row.id === params.id);
      if (row?.isDeleted) {
        event.defaultMuiPrevented = true;
      }
    },
    [rowsContext, rows],
  );
  const handleRowEditStop = useCallback<GridEventListener<'rowEditStop'>>(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
        rowsContext.setData((data) => {
          data.unsavedRows.set(params.id, {
            ...params.row,
            _action: 'changed',
          });
          return data;
        });
        if (apiRef.current.getRowMode(params.id) === GridRowModes.Edit) {
          apiRef.current.stopRowEditMode({ id: params.id });
        }
      } else if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
        rowsContext.setData((data) => {
          const unsavedRow = data.unsavedRows.get(params.id);
          if (unsavedRow && !unsavedRow.isNew) {
            data.unsavedRows.delete(params.id);
          }
          return data;
        });
      }
    },
    [apiRef, rowsContext],
  );

  const onSingleRevertButton = useCallback(
    (id: GridRowId, row: GridValidRowModel) => {
      if (apiRef.current.getRowMode(row.id) === GridRowModes.Edit) {
        apiRef.current.stopRowEditMode({ id: row.id, ignoreModifications: true });
      }
      setRows((oldRows) => {
        return oldRows
          .map((oldRow) => {
            if (oldRow.id === id && rowsContext.data.rowsBeforeChange.get(id)) {
              return {
                ...rowsContext.data.rowsBeforeChange.get(id),
                isDeleted: false,
                isEdited: false,
                isReseted: true,
              };
            }
            return oldRow.id === id ? { ...oldRow, isDeleted: false, isEdited: false } : oldRow;
          })
          .filter((oldRow) => oldRow.id !== id || !row.isNew);
      });

      //delete unsavedChangesRef.current.rowsBeforeChange[id];
      rowsContext.setData((data) => {
        data.unsavedRows.delete(id);
        data.rows = data.rows
          .map((oldRow) => {
            if (oldRow.id === id && rowsContext.data.rowsBeforeChange.get(id)) {
              return {
                ...rowsContext.data.rowsBeforeChange.get(id),
                isDeleted: false,
                isEdited: false,
                isReseted: true,
              };
            }
            return oldRow.id === id ? { ...oldRow, isDeleted: false, isEdited: false } : oldRow;
          })
          .filter((oldRow) => oldRow.id !== id || !row.isNew);
        return data;
      });
    },
    [apiRef, rowsContext],
  );

  const onSingleSaveButton = useCallback(
    (id: GridRowId, row: GridValidRowModel) => {
      if (apiRef.current.getRowMode(row.id) === GridRowModes.Edit) {
        apiRef.current.stopRowEditMode({ id: row.id });
      }
    },
    [apiRef],
  );

  const onSingleDeleteButton = useCallback(
    (id: GridRowId, row: GridValidRowModel) => {
      if (!row.isNew) {
        rowsContext.setData((data) => {
          if (!data.rowsBeforeChange.has(id)) {
            data.rowsBeforeChange.set(id, row);
          }
          data.unsavedRows.set(id, {
            ...row,
            isDeleted: true,
            _action: 'delete',
          });
          data.rows = data.rows.map((row) => {
            return row.id === id ? { ...row, isDeleted: true } : row;
          });
          return data;
        });
        setRows((oldRows) =>
          oldRows.map((oldRow) => {
            return oldRow.id === id ? { ...oldRow, isDeleted: true } : oldRow;
          }),
        );
        if (apiRef.current.getRowMode(row.id) === GridRowModes.Edit) {
          apiRef.current.stopRowEditMode({ id: row.id });
        }
        //apiRef.current.updateRows([row]);
      } else {
        rowsContext.setData((data) => {
          data.rowsBeforeChange.delete(id);
          data.unsavedRows.delete(id);
          data.rows = data.rows.filter((row) => row.id !== id);
          return data;
        });
        setRows((oldRows) => oldRows.filter((oldRow) => oldRow.id !== id));
      }
    },
    [apiRef, rowsContext],
  );

  const getApplySearchStarFilterFn = useCallback(
    (value: string) => {
      return (params: GridCellParams): boolean => {
        return searchStarFilter(value, [params.value?.toString() ?? ''], filter?.operator);
      };
    },
    [filter],
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isDeleted: false, isEdited: true };
      rowsContext.setData((data) => {
        data.unsavedRows.set(newRow.id, {
          ...newRow,
          _action: 'changed',
        });
        data.rows = data.rows.map((oldRow) => {
          if (oldRow.id === newRow.id) {
            return {
              ...newRow,
              isDeleted: false,
              isEdited: true,
              isReseted: false,
            };
          }
          return oldRow;
        });

        return data;
      });
      setRows((oldRows) => {
        return oldRows.map((oldRow) => {
          if (oldRow.id === newRow.id) {
            return {
              ...newRow,
              isDeleted: false,
              isEdited: true,
              isReseted: false,
            };
          }
          return oldRow;
        });
      });

      return updatedRow;
    },
    [rowsContext],
  );

  const discardChanges = useCallback(() => {
    setRows(
      (oldRows) =>
        oldRows
          .map((row) => {
            const oldRow = rowsContext.data.rowsBeforeChange.get(row.id);
            const ret = oldRow ?? row;
            return !ret.isNew ? ret : undefined;
          })
          .filter((r) => r) as GridValidRowModel[],
    );
    rowsContext.setData((data) => {
      data.rows = data.rows
        .map((row) => {
          const oldRow = rowsContext.data.rowsBeforeChange.get(row.id);
          const ret = oldRow ?? row;
          return !ret.isNew ? ret : undefined;
        })
        .filter((r) => r) as GridValidRowModel[];
      data.unsavedRows.clear();
      return data;
    });
  }, [rowsContext]);

  const onApplyMassDelete = useCallback(() => {
    const selectedRows = rows.filter((row) => rowSelectionModel.some((rid) => rid === row.id));

    rowsContext.setData((data) => {
      selectedRows.forEach((row) => {
        if (!row.isNew) {
          if (!data.rowsBeforeChange.has(row.id)) {
            data.rowsBeforeChange.set(row.id, row);
          }
          data.unsavedRows.set(row.id, {
            ...row,
            isDeleted: true,
            _action: 'delete',
          });
        } else {
          data.rowsBeforeChange.delete(row.id);
          data.unsavedRows.delete(row.id);
        }
      });
      data.rows = data.rows
        .map((oldRow) => {
          if (selectedRows.some((sRow) => sRow.id === oldRow.id)) {
            if (!oldRow.isNew) {
              return { ...oldRow, isDeleted: true };
            }
          }
          return oldRow;
        })
        .filter((row) => !row.isNew || !rowSelectionModel.some((rid) => rid === row.id));
      return data;
    });

    selectedRows.forEach((selectedRow) => {
      if (apiRef.current.getRowMode(selectedRow.id) === GridRowModes.Edit) {
        apiRef.current.stopRowEditMode({ id: selectedRow.id });
      }
    });

    setRows((oldRows) =>
      oldRows
        .map((oldRow) => {
          if (selectedRows.some((sRow) => sRow.id === oldRow.id)) {
            if (!oldRow.isNew) {
              return { ...oldRow, isDeleted: true };
            }
          }
          return oldRow;
        })
        .filter((row) => !row.isNew || !rowSelectionModel.some((rid) => rid === row.id)),
    );
  }, [apiRef, rowSelectionModel, rows, rowsContext]);

  const addRecord = useCallback(() => {
    // generate new (fake) id for table key
    const id = `new-fake-${uuid()}`;
    const newModel = {
      ...defaultValue,
      id,
      isNew: true,
    };
    setRows((oldRows) => [...oldRows, newModel]);
    //apiRef.current.startRowEditMode({ id, fieldToFocus: 'name' });
    rowsContext.setData((data) => {
      data.unsavedRows.set(id, {
        ...newModel,
        _action: 'new',
      });
      data.rows = [...data.rows, newModel];
      return data;
    });
  }, [defaultValue, rowsContext]);

  const tableDisabled = useMemo(() => riskFunction.status !== ContentTypeStatus.Draft, [riskFunction]);
  const hasUnsavedRows = useMemo(() => rowsContext.data.unsavedRows.size > 0, [rowsContext]);
  const [cellChanges, setCellChanges] = useState<Map<string, GridRowModel>>(new Map());

  const valueSetter = useCallback((col: FunctionDefinitionColumn, params: GridValueSetterParams) => {
    if (params.row[col.field] !== params.value) {
      setCellChanges((cells) => {
        cells.set(params.row.id, { ...params.row, [col.field]: params.value });
        return cells;
      });
    }
    params.row[col.field] = params.value;
    return params.row;
  }, []);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      ...riskFunctionDefinitionColumns.map((col) => {
        if (col.filterable) {
          return {
            ...col,
            filterOperators: extendStringFilterOperators(),
            getApplyQuickFilterFn: getApplySearchStarFilterFn,
          } as GridColDef;
        }
        return col as GridColDef;
      }),
      {
        field: 'actions',
        type: 'actions',
        width: 150,
        getActions: ({ id, row }) => {
          if (riskFunction.status !== ContentTypeStatus.Draft) {
            return [];
          }

          const isInEditMode = apiRef.current.getRowMode(id) === GridRowModes.Edit;

          if (row.isDeleted) {
            return [
              <GridActionsCellItem
                key={`cancel_${id}`}
                icon={<FontAwesomeIcon icon={faUndo} />}
                label='Cancel'
                className='textPrimary'
                onClick={() => {
                  onSingleRevertButton(id, row);
                }}
                color='inherit'
              />,
            ];
          }

          if (row.isNew && isInEditMode) {
            return [
              <GridActionsCellItem
                key={`save_${id}`}
                icon={<FontAwesomeIcon icon={faSave} />}
                label='Save'
                sx={{
                  color: 'success.main',
                }}
                onClick={() => {
                  onSingleSaveButton(id, row);
                }}
              />,
              <GridActionsCellItem
                key={`delete_${id}`}
                icon={<FontAwesomeIcon icon={faTrash} />}
                label='Delete'
                onClick={() => {
                  onSingleDeleteButton(id, row);
                }}
                color='inherit'
              />,
            ];
          } else if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={`save_${id}`}
                icon={<FontAwesomeIcon icon={faSave} />}
                label='Save'
                sx={{
                  color: 'success.main',
                }}
                onClick={() => {
                  onSingleSaveButton(id, row);
                }}
              />,
              <GridActionsCellItem
                key={`cancel_${id}`}
                icon={<FontAwesomeIcon icon={faUndo} />}
                label='Cancel'
                className='textPrimary'
                onClick={() => {
                  onSingleRevertButton(id, row);
                }}
                color='inherit'
              />,
            ];
          }

          if (!row.isNew && row.isEdited && !row.isReseted) {
            return [
              <GridActionsCellItem
                key={`edit_${id}`}
                icon={<FontAwesomeIcon icon={faEdit} />}
                label='Edit'
                className='textPrimary'
                onClick={() => {
                  onSingleEditButton(id, row);
                }}
                color='inherit'
              />,
              <GridActionsCellItem
                key={`cancel_${id}`}
                icon={<FontAwesomeIcon icon={faUndo} />}
                label='Cancel'
                className='textPrimary'
                onClick={() => {
                  onSingleRevertButton(id, row);
                }}
                color='inherit'
              />,
              <GridActionsCellItem
                key={`delete_${id}`}
                icon={<FontAwesomeIcon icon={faTrash} />}
                label='Delete'
                onClick={() => {
                  onSingleDeleteButton(id, row);
                }}
                color='inherit'
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key={`edit_${id}`}
              icon={<FontAwesomeIcon icon={faEdit} />}
              label='Edit'
              className='textPrimary'
              onClick={() => {
                onSingleEditButton(id, row);
              }}
              color='inherit'
            />,
            <GridActionsCellItem
              key={`delete_${id}`}
              icon={<FontAwesomeIcon icon={faTrash} />}
              label='Delete'
              onClick={() => {
                onSingleDeleteButton(id, row);
              }}
              color='inherit'
            />,
          ];
        },
      },
    ];
  }, [
    riskFunctionDefinitionColumns,
    getApplySearchStarFilterFn,
    riskFunction.status,
    apiRef,
    onSingleRevertButton,
    onSingleSaveButton,
    onSingleDeleteButton,
    onSingleEditButton,
  ]);

  const setRowsFromRiskFunction = useCallback(
    (riskFunction: RiskFunctionDto) => {
      const values = riskFunction.values
        .filter((val) => val.type.name === contentTypeName)
        .map((val) => {
          return {
            riskFunctionName: riskFunction.name,
            ...val,
          };
        });

      rowsContext.setData((data) => {
        data.rows = values.map((value) => {
          return riskFunctionValueToRow(value);
        });
        return data;
      });
      setRows(() =>
        values.map((value) => {
          return riskFunctionValueToRow(value);
        }),
      );
    },
    [contentTypeName, riskFunctionValueToRow, rowsContext],
  );

  const saveAll = useCallback(async () => {
    try {
      // Persist updates in the database
      setIsSaving(true);
      setInited(false);
      rowsContext.data.unsavedRows.forEach((unsavedRow, rowId) => {
        if (apiRef.current.getRowMode(rowId) === GridRowModes.Edit) {
          apiRef.current.stopRowEditMode({ id: rowId });
        }
      });
      /// @FIXME: wait for table edits
      setTimeout(async () => {
        const newRiskFunction = await saveValues();
        rowsContext.setData((data) => {
          data.rowsBeforeChange.clear();
          data.unsavedRows.clear();
          return data;
        });
        /// @NOTE: riskFunction already set from parent
        setRowsFromRiskFunction(newRiskFunction ?? riskFunction);
        setIsSaving(false);
      }, 1200);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  }, [apiRef, riskFunction, rowsContext, saveValues, setRowsFromRiskFunction]);

  const getRowClassName = useCallback(
    (id: GridRowId) => {
      const unsavedRow = rowsContext.data.unsavedRows.get(id);
      if (unsavedRow) {
        if (unsavedRow.isNew || unsavedRow._action === 'new') {
          return 'row--new';
        } else if (unsavedRow.isDeleted || unsavedRow._action === 'delete') {
          return 'row--removed';
        }

        return 'row--edited';
      }
      return '';
    },
    [rowsContext],
  );

  const [inited, setInited] = useState(false);
  useEffect(() => {
    setRowsFromRiskFunction(riskFunction);
  }, [riskFunction /*, setRowsFromRiskFunction*/]);
  /// @NOTE: adding setRowsFromRiskFunction will break the function for adding a new entry

  useEffect(() => {
    apiRef.current.setQuickFilterValues(filter?.search.split(' ').filter((word) => word !== '') ?? []);
  }, [apiRef, filter?.search]);

  return (
    <Stack direction={'column'} spacing={1}>
      <Box sx={{ minHeight: 200, height: 300, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          editMode={!tableDisabled ? 'row' : undefined}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          checkboxSelection={!tableDisabled}
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableColumnSelector
          disableDensitySelector
          density='compact'
          slots={{
            noRowsOverlay: FunctionsNoRowsOverlay,
            footer: FunctionDefinitionsDataGridFooter,
          }}
          slotProps={{
            footer: {
              selected: rowSelectionModel.length,
              onApplyMassDelete: onApplyMassDelete,
            },
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLogicOperator.Or,
              },
            },
          }}
          loading={isSaving}
          localeText={
            {
              filterOperatorDoesntContain: 'does not contain',
              filterOperatorDoesntEqual: 'does not equal',
            } as any
          }
          /* @FIXME: Object literal may only specify known properties, and '"filterOperatorNoContain"' does not exist in type 'Partial<GridLocaleText>'. */
          getRowClassName={({ id }) => getRowClassName(id)}
          sx={{
            '--DataGrid-overlayHeight': 300,
            '.MuiDataGrid-virtualScroller': {
              overflowY: 'auto !important',
              // example of maxHeight
              maxHeight: '40vh !important',
            },
            '& .MuiDataGrid-row.row--removed': {
              backgroundColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return 'rgba(255, 170, 170, 0.3)';
                }
                return darken('rgba(255, 170, 170, 1)', 0.7);
              },
            },
            '& .MuiDataGrid-row.row--edited': {
              backgroundColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return 'rgba(255, 254, 176, 0.3)';
                }
                return darken('rgba(255, 254, 176, 1)', 0.6);
              },
            },
            '& .MuiDataGrid-row.row--new': {
              backgroundColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return 'rgba(176, 254, 176, 0.3)';
                }
                return darken('rgba(176, 254, 176, 1)', 0.6);
              },
            },
          }}
        />
      </Box>
      <Stack direction={'row'} spacing={2}>
        <Button
          disabled={isSaving || riskFunction.status !== ContentTypeStatus.Draft}
          onClick={() => saveAll()}
          endIcon={<FontAwesomeIcon icon={faCheck} />}
          color='success'
          variant='contained'
        >
          Save
        </Button>
        <Button
          disabled={isSaving || riskFunction.status !== ContentTypeStatus.Draft}
          onClick={addRecord}
          endIcon={<FontAwesomeIcon icon={faAdd} />}
          color='success'
          variant='contained'
        >
          New Entry
        </Button>
        {/* <Button
          disabled={isSaving || !hasUnsavedRows || riskFunction.status !== ContentTypeStatus.Draft}
          onClick={discardChanges}
          startIcon={<FontAwesomeIcon icon={faUndo} />}
          color='warning'
          variant='contained'
        >
          Discard Changes
        </Button> */}
      </Stack>
    </Stack>
  );
}

export default FunctionsDataGrid;
