import { Autocomplete, Grid, TextField } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';
import { CreateRiskFunctionDto } from '@/services/dto/functions/create-function.dto';
import { RiskFunctionDto } from '@/services/dto/functions/function.dto';

import { ContentTableModal } from '../../../components/content-table-modal';
import { RiskDto } from '@/services/dto/risks/risk.dto';

interface CreateRiskFunctionFormData {
  name: string;
  displayName: string;
  businessProcessId: string;
  description: string;
}

interface RiskFunctionModalProps {
  open: boolean;
  allBusinessProcesses: BusinessProcessDto[];
  risk?: RiskDto;
  onClose: () => void;
  onRiskFunctionCreate: (data: CreateRiskFunctionDto) => Promise<void>;
}

const CreateRiskFunctionModal: FunctionComponent<RiskFunctionModalProps> = ({
  open,
  allBusinessProcesses,
  risk,
  onClose,
  onRiskFunctionCreate,
  ...props
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRiskFunctionFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      businessProcessId: '',
      description: '',
    },
  });

  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState<BusinessProcessDto | null>(null);
  const [newBusinessProcessName, setNewBusinessProcessName] = useState('');

  const onCreate = useCallback(
    async (data: FieldValues) => {
      onRiskFunctionCreate({
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        businessProcessId: data.businessProcessId || null,
        riskId: risk?.id
      });
    },
    [onRiskFunctionCreate],
  );

  const onAutocompleteChange = useCallback(
    (_: any, newSelectedBusinessProcess: BusinessProcessDto | null) => {
      setSelectedBusinessProcess(newSelectedBusinessProcess);
      setValue('businessProcessId', newSelectedBusinessProcess?.id ?? '');
    },
    [setSelectedBusinessProcess, setValue],
  );

  const registerOptions = {
    businessProcessId: undefined,
  };

  return (
    <ContentTableModal<RiskFunctionDto, CreateRiskFunctionFormData>
      title='Create Function'
      type='Create'
      create={{
        onCreate,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors },
      }}
    >
      <Grid item xs={12}>
        <Controller
          name='businessProcessId'
          control={control}
          rules={registerOptions.businessProcessId}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={selectedBusinessProcess}
              onChange={onAutocompleteChange}
              inputValue={newBusinessProcessName}
              onInputChange={(_, newBusinessProcessName) => {
                setNewBusinessProcessName(newBusinessProcessName);
              }}
              options={allBusinessProcesses}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
              renderInput={(params) => (
                <TextField {...params} margin='dense' label='Business Process' InputLabelProps={{ shrink: true }} />
              )}
            />
          )}
        />
      </Grid>
    </ContentTableModal>
  );
};

export default CreateRiskFunctionModal;
