import {
  faArrowDownToDottedLine,
  faArrowUpArrowDown,
  faArrowUpToDottedLine,
  faBarsProgress,
  faBinRecycle,
  faBook,
  faBookUser,
  faBooks,
  faBriefcase,
  faChartSimple,
  faClipboard,
  faCommentsQuestionCheck,
  faCompassDrafting,
  faEnvelope,
  faFunction,
  faGridHorizontal,
  faShop,
  faTriangleExclamation,
  faUsers,
} from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BusinessProcessesPage from 'src/pages/business-processes';
import CriticalityPage from 'src/pages/criticality';

import ContactSupportPage from '../pages/contact-support';
import DashboardPage from '../pages/dashboard';
import ExportRulesetPage from '../pages/export-ruleset';
import FaqPage from '../pages/faq';
import FunctionDetailsPage from '../pages/function-details';
import FunctionsPage from '../pages/functions';
import GuidancePage from '../pages/guidance';
import ImportRulesetPage from '../pages/import-ruleset';
import ProfilePage from '../pages/profile';
import RiskDetailsPage from '../pages/risk-details';
import RisksPage from '../pages/risks';
import RulesetDetailsPage from '../pages/ruleset-details';
import RulesetsPage from '../pages/rulesets';
import TrashPage from '../pages/trash';
import UsersPage from '../pages/users';
import { UserDto } from '../services/dto/users/user.dto';
import mockupRoutes from './mockup-routes';
import { RouteMenuElement } from './route-menu-element';
import RolesUsersOverviewPage from '../pages/roles-users-overview';
import AnalyticsPage from '../pages/analytics';
import MarketplacePage from '../pages/marketplace';

const mainRoutes: RouteMenuElement[] = [
  {
    sidebarProps: {
      text: 'Menu',
    },
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    sidebarProps: {
      title: 'Dashboard',
      icon: <FontAwesomeIcon icon={faGridHorizontal} />,
    },
  },
  {
    // User Management
    access: {
      permissions: ['users:create', 'users:read', 'users:update', 'users:delete'],
    },
    sidebarProps: {
      title: 'Users',
      icon: <FontAwesomeIcon icon={faUsers} />,
    },
    path: '/users',
    element: <UsersPage />,
    // children: [
    //   {
    //     path: '/users',
    //     element: <UsersPage />,
    //     sidebarProps: {
    //       title: 'Users',
    //       icon: <FontAwesomeIcon icon={faUsers} />,
    //     },
    //     access: {
    //       permissions: ['users:create', 'users:read', 'users:update', 'users:delete'],
    //     },
    //   },
    // ],
  },
  {
    sidebarProps: {
      text: 'Rulesets',
    },
    access: {
      permissions: ['rulesets:create', 'rulesets:read', 'rulesets:update', 'rulesets:delete'],
    },
  },
  {
    path: '/rulesets',
    element: <RulesetsPage />,
    sidebarProps: {
      title: 'Rulesets',
      icon: <FontAwesomeIcon icon={faBooks} />,
    },
    access: {
      permissions: ['rulesets:create', 'rulesets:read', 'rulesets:update', 'rulesets:delete'],
    },
  },
  {
    // Risks
    access: {
      permissions: ['rulesets:read:risks', 'risks:create', 'risks:read', 'risks:update', 'risks:delete'],
    },
    sidebarProps: {
      title: 'Risks',
      icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
    },
    path: '/risks',
    element: <RisksPage />,
  },
  {
    // Functions
    access: {
      permissions: [
        'rulesets:read:functions',
        'functions:create',
        'functions:read',
        'functions:update',
        'functions:delete',
      ],
    },
    sidebarProps: {
      title: 'Functions',
      icon: <FontAwesomeIcon icon={faFunction} />,
    },
    path: '/functions',
    element: <FunctionsPage />,
  },
  {
    path: '/analytics',
    element: <AnalyticsPage />,
    sidebarProps: {
      title: 'Analytics',
      icon: <FontAwesomeIcon icon={faChartSimple} />,
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
  },
  {
    path: '/marketplace',
    element: <MarketplacePage />,
    sidebarProps: {
      title: 'Marketplace',
      icon: <FontAwesomeIcon icon={faShop} />,
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
  },
  {
    // Import/Export
    sidebarProps: {
      title: 'Import / Export',
      icon: <FontAwesomeIcon icon={faArrowUpArrowDown} />,
    },
    access: {
      permissions: ['import:create', 'export:read'],
    },
    children: [
      {
        path: '/import/ruleset',
        element: <ImportRulesetPage />,
        sidebarProps: {
          title: 'Import Ruleset',
          icon: <FontAwesomeIcon icon={faArrowUpToDottedLine} />,
        },
      },
      {
        path: '/export/ruleset',
        element: <ExportRulesetPage />,
        sidebarProps: {
          title: 'Export Ruleset',
          icon: <FontAwesomeIcon icon={faArrowDownToDottedLine} />,
        },
      },
    ],
  },
  {
    // Master Data
    sidebarProps: {
      title: 'Master Data',
      icon: <FontAwesomeIcon icon={faBookUser} />,
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    children: [
      {
        path: '/risk-level',
        element: <CriticalityPage />,
        sidebarProps: {
          title: 'Risk Level',
          icon: <FontAwesomeIcon icon={faBarsProgress} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      {
        path: '/business-processes',
        element: <BusinessProcessesPage />,
        sidebarProps: {
          title: 'Business Processes',
          icon: <FontAwesomeIcon icon={faBriefcase} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      // {
      //   path: '/connector-groups',
      //   element: <ConnectorGroupsPage />,
      //   sidebarProps: {
      //     title: 'Connector Groups ',
      //     icon: <FontAwesomeIcon icon={faUserLock} />,
      //   },
      //   access: {
      //     roles: ['client', 'admin', 'superadmin'],
      //   },
      // },
      // {
      //   path: '/system-types',
      //   element: <SystemTypesPage />,
      //   sidebarProps: {
      //     title: 'System Types',
      //     icon: <FontAwesomeIcon icon={faUserLock} />,
      //   },
      //   access: {
      //     roles: ['client', 'admin', 'superadmin'],
      //   },
      // },
    ],
  },
  {
    path: '/trash',
    element: <TrashPage />,
    sidebarProps: {
      title: 'Trash',
      icon: <FontAwesomeIcon icon={faBinRecycle} />,
    },
    access: {
      permissions: [
        'rulesets:read',
        'rulesets:update',
        'rulesets:delete',
        'rulesets:read:risks',
        'risks:read',
        'risks:update',
        'risks:delete',
        'rulesets:read:functions',
        'functions:read',
        'functions:update',
        'functions:delete',
      ],
    },
  },
  {
    sidebarProps: {
      text: 'Support',
    },
  },
  {
    path: '/faq',
    element: <FaqPage />,
    sidebarProps: {
      title: 'FAQs',
      icon: <FontAwesomeIcon icon={faCommentsQuestionCheck} />,
    },
  },
  {
    sidebarProps: {
      title: 'Guidance',
      icon: <FontAwesomeIcon icon={faBook} />,
    },
    children: [
      {
        path: '/guidance/ruleset-design-xams',
        element: <GuidancePage />,
        sidebarProps: {
          title: 'Ruleset Design',
          icon: <FontAwesomeIcon icon={faCompassDrafting} />,
        },
      },
    ],
  },
  {
    path: '/contact',
    element: <ContactSupportPage />,
    sidebarProps: {
      title: 'Support',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
    },
  },
  {
    path: '/user/profile',
    element: <ProfilePage />,
    access: {
      permissions: ['self:read', 'self:update'],
    },
  },
  // Ruleset Details
  {
    path: '/rulesets/:rulesetId',
    element: <RulesetDetailsPage />,
    access: {
      permissions: ['rulesets:read:risks', 'risks:create', 'risks:update', 'risks:read', 'risks:delete'],
    },
  },
  // Risk Details
  {
    path: '/rulesets/:rulesetId/risks/:riskId',
    element: <RiskDetailsPage />,
    access: {
      permissions: [
        'rulesets:read:risks',
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  {
    path: '/risks/:riskId',
    element: <RiskDetailsPage />,
    access: {
      permissions: [
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  // Function Details
  {
    path: '/rulesets/:rulesetId/risks/:riskId/functions/:riskFunctionId',
    element: <FunctionDetailsPage />,
    access: {
      permissions: [
        'rulesets:read:risks',
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  {
    path: '/risks/:riskId/functions/:riskFunctionId',
    element: <FunctionDetailsPage />,
    access: {
      permissions: [
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  {
    path: '/functions/:riskFunctionId',
    element: <FunctionDetailsPage />,
    access: {
      permissions: ['functions:create', 'functions:update', 'functions:read', 'functions:delete'],
    },
  },
];

export function getAllMainRoutes() {
  return [...mainRoutes/*, ...mockupRoutes*/];
}

export function getMainMenuElements(userData: UserDto) {
  return getAllMainRoutes().filter((route) => {
    return (
      (!route.access?.roles || route.access.roles.includes(userData.role.name)) &&
      (!route.access?.permissions ||
        route.access.permissions.some((permission) => userData.role.permissions.includes(permission)))
    );
  });
}

export default mainRoutes;
