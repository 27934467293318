import { XspOdataExportRulesetDto } from '../dto/export/xsp-export-rulesets.dto';
import { HttpService } from '../http.service';

export class XspOdataExportService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('export/xsp-odata')) {}

  async exportXspAll(
    rulesetIds: string[],
    functionContentTypeName: string,
    auth: {
      baseUrl: string;
      baseAuthUser: string;
      basesAuthPassword: string;
    },
  ) {
    const response = await this.httpService.post(`all`, {
      rulesetIds,
      functionContentTypeName,
      baseUrl: auth.baseUrl,
      baseAuthUser: auth.baseAuthUser,
      basesAuthPassword: auth.basesAuthPassword,
      activate: true,
    } as XspOdataExportRulesetDto);
    return response.data;
  }
}
